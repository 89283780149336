import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/posts-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I worked for one year and a half on the same React application that I kickstarted. As I left the company and stopped working on it in May, it’s now an excellent time to write about all the takeaways I have from this experience. It’s all about UI development and about decisions I (or we, as a team) made whether they had a positive or negative outcome.`}</p>
    <p>{`Those points are made to be succinct, I’ll turn a few points I like into their own blog posts when I have time.`}</p>
    <h2>{`Write scalable maintainable code with a design system`}</h2>
    <p><strong parentName="p">{`Making a web app is hard but maintaining it is even harder.`}</strong>{` You should write your code to be `}<strong parentName="p">{`disposable`}</strong>{` and `}<strong parentName="p">{`recyclable`}</strong>{` if you want to cope with the infernal pace required by quick and drastic design changes. Those will and should happen no matter the type of project management you are using.`}</p>
    <p>{`A design system is a set of extremely generic components like a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Box`}</code>{` component that can be easily styled only with JSX props. You end up with code like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Box`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`marginTop`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`10px`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`marginBottom`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`20px`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`borderBottom`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`1px solid blue`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
  `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Flex`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
    `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Text`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`fontWeight`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`bold`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`Hello`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Text`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
    `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Text`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`color`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`red`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`World`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Text`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
  `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Flex`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Box`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`It makes it much easier to update a layout, you can add or remove boxes, elements, style properties without losing the context.
The performance is good enough in 99% of the cases but not optimal. If one part of your application is slow, optimise just that one not everything. You don’t need every single `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`div`}</code>{` of your application to be extremely fast, `}<strong parentName="p">{`you do need them to be extremely easy to maintain`}</strong>{`.`}</p>
    <p>{`You can use variables if your designer has a predefined set of colours and font sizes. I recommend `}<a parentName="p" {...{
        "href": "https://styled-system.com/"
      }}>{`Styled System`}</a>{` to create your own design system, so you can feed whichever style variables you want through your whole app.`}</p>
    <h2>{`Cypress, the ultimate UI testing tool`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.cypress.io/"
      }}>{`Cypress.io`}</a>{` provides tools and interfaces to make visual UI tests easy to write and debug. It caught many regression bugs for us and worked well with our CI pipeline. Cypress takes screenshots and videos of every failure, so it’s easy to reproduce bugs. This tool was my best discovery during my time at the company.`}</p>
    <p>{`I could feel the sense of security while developing, knowing that Cypress would catch regressions. Writing tests was also straightforward since they provide an easy-to-use API which is easy to extend.`}</p>
    <h2>{`Unit tests double the amount of code`}</h2>
    <p>{`Requirements and designs will change potentially very often. When you are writing a unit test, keep in mind that they are going to change as often as the code they are testing then ask yourself if you need this unit test. `}<strong parentName="p">{`Unit tests are extra code to maintain so make sure that they are useful, and that you know which bugs you want them to catch.`}</strong>{` If your component looks like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`MyComponent`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`This component only renders the introduction text of my website.`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`You probably don’t need a unit test. You can think a unit test doesn’t hurt so you could add a test checking for typos in the text, `}<a parentName="p" {...{
        "href": "https://www.philosophicalhacker.com/post/maybe-dont-write-that-test/"
      }}>{`please don’t`}</a>{`. If you do that's going to be one string to update twice in two different files every time this text changes. If the markup is more complex than this, every time you change the layout, you’ll have to update your tests. Don’t write test just for the sake of having 100% coverage. You want to catch bugs.`}</p>
    <p>{`Write unit tests for components that are widely reused throughout your application because if you update it in a specific use case, it might break others. Although, if your component is only a `}<a parentName="p" {...{
        "href": "https://www.styled-components.com/"
      }}>{`styled component`}</a>{`, maybe don’t. `}<strong parentName="p">{`It’s fine to remove unit tests if you think they don’t provide any value.`}</strong></p>
    <h2>{`A bad unit test is worse than no unit test`}</h2>
    <p>{`Keep in mind that you are going to refactor your application many times. For example, `}<a parentName="p" {...{
        "href": "https://medium.com/@dan_abramov/you-might-not-need-redux-be46360cf367"
      }}>{`you want to remove Redux`}</a>{`, you want to use `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/hooks-intro.html"
      }}>{`hooks`}</a>{`, or you want to switch to `}<a parentName="p" {...{
        "href": "https://medium.com/dailyjs/what-is-actually-css-in-js-f2f529a2757"
      }}>{`CSS-in-JS`}</a>{`. It might require to rewrite many unit tests.`}</p>
    <p><strong parentName="p">{`Test the business logic rather than the markup.`}</strong>{` If the numbers are not correct, or if the menu doesn’t open, it’s a bigger problem than a misplaced `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`div`}</code>{`. I usually isolate business logic from the view as much as possible, so that I can test them separately. If you have tricky algorithms in your business logic, unit tests are also a suitable way to optimise them and expect the same results.`}</p>
    <h2>{`Specific code styles don’t matter, but you need guidelines`}</h2>
    <p>{`I don’t care whether you prefer spaces or tabs, but a whole codebase has to use either one or the other. It doesn’t matter if you like file names to be `}<a parentName="p" {...{
        "href": "https://medium.com/better-programming/string-case-styles-camel-pascal-snake-and-kebab-case-981407998841"
      }}>{`kebab cased or pascal cased`}</a>{`, if you like one component per file or your styles next to your component but `}<strong parentName="p">{`make sure your whole codebase is consistent in using one particular style`}</strong>{`. Enforce it in code reviews, use tools like `}<a parentName="p" {...{
        "href": "https://eslint.org/"
      }}>{`Eslint`}</a>{`, provide tools like `}<a parentName="p" {...{
        "href": "https://prettier.io/"
      }}>{`Prettier`}</a>{` and make sure everyone uses them.`}</p>
    <p>{`The main reason is that it makes your codebase easier to apprehend for newcomers whether they are junior or senior. You can get used to any style possible after a while. You don’t need company-wide guidelines although it’s easier to switch from a project to another if you have them.`}</p>
    <h2>{`There’s nothing wrong global state (nor with Redux)`}</h2>
    <p>{`I remember when a “You might not need Redux” movement kicked off. Many people complained on Twitter about boilerplates, about its verbosity, about the difficulty to include it in a project, Dan Abramov wrote that `}<a parentName="p" {...{
        "href": "https://medium.com/@dan_abramov/you-might-not-need-redux-be46360cf367"
      }}>{`blog post`}</a>{` and everyone was going for alternatives like `}<a parentName="p" {...{
        "href": "https://github.com/jamiebuilds/unstated"
      }}>{`Unstated`}</a>{` or the good old `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`setState`}</code>{`. I could totally relate to all that, so I just replaced Redux by a mix of setState and Context in many parts of our application.`}</p>
    <p>{`I started isolating trees of data, creating several local states, disconnected from our Redux store starting with elements that I thought would never be connected. For example, let’s say there is a dropdown menu in the header and tabs on the main page. We could split the states apart in different trees updated by a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`setState`}</code>{` call and spread by Context. Remember the first paragraph: `}<strong parentName="p">{`your application will often change drastically all along its lifetime`}</strong>{`. Our designer now decides that the menu in the header should be on the main page, and one of its options depends on your selected tab. The tab and the dropdown menu are now interdependent. We now have to refactor parts of the page to have those two states available in the same place. It took me a few hours to figure it out. Although if everything were already in a single store, it would have taken me less than an hour to move a few components around.`}</p>
    <p>{`In this case, `}<strong parentName="p">{`leaving everything in Redux was the right thing to do to keep our website maintainable`}</strong>{`. I'm not trying to sell Redux here and it might not be the most relevant example. You can use any other tool or a mix of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`useState`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`useReducer`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`useContext`}</code>{` but having a globally available state is helpful to make your application maintainable.`}</p>
    <h2>{`Take other people’s opinions with a pinch of salt`}</h2>
    <p>{`Don’t make decisions based on other people’s experience of entirely different applications. One thing can be right for one app and wrong for another one. When you read articles and tweets, `}<strong parentName="p">{`you should learn from the process leading them to an opinion, do not just take their conclusion as a general truth`}</strong>{`. You should be even more careful when they are very persuasive and adopt a tone of “you `}<em parentName="p">{`should`}</em>{`/`}<em parentName="p">{`shouldn’t`}</em>{` do this”. This advice is relevant to this article as well. I might change my mind on many of the opinions expressed in this post. `}<strong parentName="p">{`Open-mindedness is possibly the most valuable skill a software developer can have`}</strong>{`.`}</p>
    <h2>{`Conclusion`}</h2>
    <blockquote className="twitter-tweet" data-conversation="none" data-lang="en" data-theme="light">
  <p lang="en" dir="ltr">
    Large scale design changes are never easy.
    <br />
    <br />
    But they&#39;re way more difficult if you have never wiped the slate clean and
    have no way to implement a one-off outside of whatever frontend tech is driving
    the rest of your stack.
    <br />
    <br />
    Build your frontend to be easily thrown away.
  </p>
  &mdash; Baby Wolfman (@sambreed) <a href="https://twitter.com/sambreed/status/1183422226776768512?ref_src=twsrc%5Etfw">
    October 13, 2019
  </a>
    </blockquote>
    <p>{`New front end coding trends, breaking changes in a library update, project design tweaks, API updates, those will inevitably occur, and you can’t blame anyone for that. Whatever you’re building, `}<strong parentName="p">{`keep an open mind`}</strong>{` and `}<strong parentName="p">{`think about long term design and maintainability first`}</strong>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      